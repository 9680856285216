import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { GridList, Body1, Caption1 } from '@class101/ui';
import { imageSrc } from './interface';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "gridlist"
    }}>{`GridList`}</h1>
    <p>{`Class101 UI의 Grid 시스템 기반의 리스트입니다.
모바일에서 2컬럼일 경우만 커터의 크기가 다릅니다.(8px -> 16px)`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={GridList} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<GridList\n  keyExtractor={(item, index) => item._id}\n  items={[\n    {\n      _id: \'abc1\',\n      title: \'질문에 칼답 클래스 기획전\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'10\',\n    },\n    {\n      _id: \'abc2\',\n      title: \'질문에 칼답 클래스 기획전\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'20\',\n    },\n    {\n      _id: \'def3\',\n      title: \'질문에 칼답 클래스 기획전 질문에 칼답 클래스 기획전\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'30\',\n    },\n    {\n      _id: \'ghi4\',\n      title: \'질문에 칼답 클래스 기획전\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'40\',\n    },\n    {\n      _id: \'lmn5\',\n      title: \'질문에 칼답 클래스 기획전 질문에 칼답 클래스 기획전\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'40\',\n    },\n    {\n      _id: \'opq6\',\n      title: \'질문에 칼답 클래스 기획전 질문에\',\n      image: imageSrc,\n      productState: \'바로 수강 가능\',\n      discount: \'40\',\n    },\n  ]}\n  smColumn={2}\n  lgColumn={4}\n  renderItem={item => {\n    const { title, image, productState, discount } = item\n    return (\n      <div>\n        <div>\n          <img style={{ width: \'100%\' }} src={image} alt={title} />\n        </div>\n        <Body1>{title}</Body1>\n        <Caption1>{productState}</Caption1>\n        <Caption1 fontWeight=\"Bold\" color=\"orange\">\n          {discount}% 할인\n        </Caption1>\n      </div>\n    )\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      GridList,
      Body1,
      Caption1,
      imageSrc,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <GridList keyExtractor={(item, index) => item._id} items={[{
        _id: 'abc1',
        title: '질문에 칼답 클래스 기획전',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '10'
      }, {
        _id: 'abc2',
        title: '질문에 칼답 클래스 기획전',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '20'
      }, {
        _id: 'def3',
        title: '질문에 칼답 클래스 기획전 질문에 칼답 클래스 기획전',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '30'
      }, {
        _id: 'ghi4',
        title: '질문에 칼답 클래스 기획전',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '40'
      }, {
        _id: 'lmn5',
        title: '질문에 칼답 클래스 기획전 질문에 칼답 클래스 기획전',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '40'
      }, {
        _id: 'opq6',
        title: '질문에 칼답 클래스 기획전 질문에',
        image: imageSrc,
        productState: '바로 수강 가능',
        discount: '40'
      }]} smColumn={2} lgColumn={4} renderItem={item => {
        const {
          title,
          image,
          productState,
          discount
        } = item;
        return <div>
          <div>
            <img style={{
              width: '100%'
            }} src={image} alt={title} />
          </div>
          <Body1 mdxType="Body1">{title}</Body1>
          <Caption1 mdxType="Caption1">{productState}</Caption1>
          <Caption1 fontWeight="Bold" color="orange" mdxType="Caption1">
            {discount}% 할인
          </Caption1>
        </div>;
      }} mdxType="GridList" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      